import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faReddit, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    RedditShareButton,
} from "react-share";

import "./Share.scss";

const Share = ({
    config
}) => {
    return (
        <div className={`share ${config.inHeader ? "header-share" : "body-share"}`}>
            <FacebookShareButton className="facebook" url={config.url} >
                <FontAwesomeIcon icon={faFacebook} />
            </FacebookShareButton>
            <RedditShareButton className="reddit" url={config.url} >
                <FontAwesomeIcon icon={faReddit} />
            </RedditShareButton>
            <TwitterShareButton className="twitter" url={config.url} via={config.twitter.split("@").join("")}>
                <FontAwesomeIcon icon={faTwitter} />
            </TwitterShareButton>
            <LinkedinShareButton className="linkedin" url={config.url}>
                <FontAwesomeIcon icon={faLinkedin} />
            </LinkedinShareButton>
        </div>
    );
};

export default Share;