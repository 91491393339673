import React, { Fragment } from "react";
import { graphql } from "gatsby";

import "./BlogPost.scss";
import Layout from "../Layout";
import Section from "../Section";
import SEO from "../SEO";
import Share from "../Share";

const BlogPost = ({
    data: {
        ghostPost: post,
        site: { siteMetadata }
    }
}) => {
    const {
        siteUrl: baseURL,
        twitter
    } = siteMetadata;
    const {
        title,
        feature_image,
        published_at,
        excerpt,
        slug,
        html
    } = post;

    const shareConfig = {
        url: `${baseURL}/blog/${slug}`,
        title,
        twitter
    };

    const headerContent = <Fragment>
        <h1>{title}</h1>
        <div className="header-meta text-secondary mt-2">
            <span>{published_at}</span>
        </div>
        {/* <Share config={{
            ...shareConfig,
            inHeader: true
        }} /> */}
    </Fragment>;

    return (
        <Layout
            headerChildren={headerContent}
            path="/blog"
        >
            <SEO
                blogPost
                pathname={`/blog/${slug}`}
                title={title}
                description={excerpt}
                meta={[
                    <meta property="og:type" content="article" />,
                    <meta property="og:image" content={feature_image} />,
                    <meta property="twitter:image" content={feature_image} />
                ]}
            />
            <Section margin="mb-16 mt-8 md:my-16">
                <div id="BlogPost" className="px-4">
                    <img
                        className="mx-auto feature-image mb-2"
                        loading="lazy"
                        src={feature_image}
                        alt={title}
                    />
                    <div className="text-muted italic text-sm mb-6 text-base">{excerpt}</div>
                    <article
                        className="mx-auto"
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                    <div className="flex flex-col items-center justify-center mt-6">
                        <h2 className="text-center mb-4">Enjoyed this post? Pass it on!</h2>
                        <Share config={{
                            ...shareConfig,
                            inHeader: false
                        }}/>
                    </div>
                </div>
            </Section>
        </Layout>
    );
};

export default BlogPost;

export const postQuery = graphql`
    query($slug: String!){
        ghostPost(slug: { eq: $slug }) {
            title
            slug
            feature_image
            published_at(formatString: "MMMM DD, YYYY")
            excerpt
            html
        }
        site {
            siteMetadata {
                siteUrl
                twitter
            }
        }
    }
`;